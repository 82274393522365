@charset "utf-8";

$link-color: #025381;
$link-color-hover: #03334e;
$right-sidebar-width: 0px !default;
$right-sidebar-width-narrow: 0px !default;
$right-sidebar-width-wide: 0px !default;

@import "minimal-mistakes/skins/contrast"; // skin
@import "minimal-mistakes"; // main partials

body{ font-size: 16px; }

@media screen and (min-width: 48em) {
  body{ font-size: 18px; }
  .description{ line-height:1.0em; }
}

/* ==========================================================================
   MASTHEAD
   ========================================================================== */

.masthead {
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0.5, #111111), color-stop(1, #535252));
  background-color: rgb(39, 37, 37);
  -webkit-animation: $intro-transition;
  animation: $intro-transition;
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
  z-index: 20;
  justify-content: center;

  &__inner-wrap {
    @include clearfix;
    margin-left: auto;
    margin-right: auto;
    padding: 1em;
    max-width: 100%;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-family: $sans-serif-narrow;

    nav { z-index: 10; }

    a {
      text-decoration: none;
      background-color: transparent;
    }
  }
}

.site-logo img {
  max-height: 5em;
  max-width: 6em;
}

#site-nav { margin-left: 0; }

.site-title {
  color:white;
  display: flex;
  flex-direction: column;
}

.site-subtitle { color:white; }

.logo-title {
  position: relative;
  display: flex;
  flex-flow: row no-wrap;
  -webkit-box-align: center;
  align-items: center;
  -ms-flex-align: center;
  width: 100%;
  background-color: transparent;
  margin-bottom: 1em;
  justify-content: center;
  align-self: center;
  height: auto;

  .translation-unavailable { filter: grayscale(1.0); }

  .translation-link, .translation-link a {
    color:white;
    font-weight: bold;
    display: inline;
    margin-left:0%;
    margin-right: 0.5em;
  }

  .translation-link:hover { color:rgb(202, 199, 199); }
}

/* ==========================================================================
   NAVIGATION
   ========================================================================== */

.greedy-nav {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: $nav-height;
  background-color: transparent;
}

.nav-items {
  background-color: black;
  color: white;
  margin-top: 0.5em;
}

.nav-items:hover { text-decoration: none; }

.links {
  display: flex;
  flex-direction: row;
}

.site-logo-hack { @extend .site-logo; }

.site-title-hack { @extend .site-title; }

.nav_buttons {
  display: flex;
  flex-direction: flex;
  flex-wrap:wrap;
  width: auto;
  height: 5.625em;
}

.title {
  margin-right: 10%;
  align-self: center;
  margin-left: 0%;
  margin-top:1.2em;
  margin-bottom:1.2em;
}

.language_selector {
  display:flex;
  flex-direction: row;
  height: auto;
}

.lang_selector-images {
  width: 1.4em;
  height: 1.0em;
}

.search__toggle, .greedy-nav__toggle { color: rgb(220,30,30); }

.search__toggle:hover, .greedy-nav__toggle:hover{ color: rgb(255, 50, 50); }

/* ==========================================================================
   PESSOAS E PROJETOS
   ========================================================================== */

@media screen and (min-width: 1300px) {
  .person, .projeto {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-left: 0%;
    margin-right: 1em;
    border: none;
  }

  .person { width:45%; }

  .projeto #image { flex-basis: 7em; }
  
  .person #image {
    flex-basis: 5em;
    width: 40%;
  }
}

@media screen and (max-width: 1299px) {
  .persons div h2 {width: 85%; text-align: center;}

  .person, .projeto {
    display:flex;
    flex-direction: column;
    width:35%;
    height:100%;
    justify-content: center;
    align-items: center;
    border: none;
    margin-bottom: 2em;
    margin-left:25%;
  }

  .person .image { width:50%; }
}

.projeto .description, .person .description { flex-grow: 1; }

html a { text-decoration: none; }

html a:hover { text-decoration: underline; }

.projeto .imagem {
  flex-basis: 1em;
  flex-shrink: 0;
  margin-right: 1em;
}

.person .image {
  flex-basis: 1em;
  flex-shrink: 0;
  margin-right: 1em;
}

.description {
  width:auto;
  line-height:1.2em;
}

@media screen and (max-width: 200px) {
  .projeto .image { width:100%; }
}

.summary, .sumario {
  border:solid;
  border-width: 0.1em;
  line-height: 1.0;
  background-color: #a1a1a1;
  font-size: 16px;
}

